
@import '@/assets/styles/_colors.scss';
@import '@/assets/styles/_fonts.scss';
.common-button {
  @extend .fsbtn16;
  @extend .grey900;
  @extend .fw500;
  width: max-content;
  height: 56px;
  padding: 18px 28px 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: inherit;
  cursor: pointer;
  .fixed {
    padding: 18px 20px 19px;
    @include touch {
      max-width: 460px;
      margin: 0 auto;
    }
  }
  &.defaultGhost {
    border: 1px solid $grey300;
    &:hover {
      border: 1px solid $grey900;
    }
    &:active {
      background-color: $grey100;
    }
    &:disabled {
      background-color: $white;
      border: 1px solid $grey300;
      color: $grey400;
    }
    &.invalid {
      border: 1px solid $red500;
      background-color: $white;
    }
  }
  &.white-ghost {
    border: 1px solid white;
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:active {
      background-color: rgba(255, 255, 255, 0.5);
    }
    &:disabled {
      border: 1px solid $grey300;
      color: $grey400;
    }
    &.invalid {
      border: 1px solid $red500;
    }
  }
  &.defaultSolid {
    background-color: $grey100;
    border: none;
    &:hover {
      background-color: $grey300;
    }
    &:disabled {
      background-color: $grey50;
      color: $grey400;
    }
    &.invalid {
      background-color: $red100;
    }
  }
  &.primarySolid {
    border: none;
    color: $white;
    background-color: $verdigris500;
    &:hover {
      background-color: $verdigris700;
    }
    &:disabled {
      background-color: $verdigris100;
    }
    &.invalid {
      background-color: $red500;
    }
  }
  &.primaryWhite {
    background-color: white;
  }
  &.primaryWhite,
  &.primaryGhost {
    border: 1px solid $verdigris500;
    color: $verdigris500;
    &:hover {
      background-color: $verdigris100;
    }
    &:active {
      background-color: $verdigris300;
    }
    &:disabled {
      background-color: initial;
      border: 1px solid $verdigris100;
      color: $verdigris300;
    }
    &.invalid {
      background-color: initial;
      border: 1px solid $red500;
      color: $red500;
    }
  }
  &.small {
    @extend .fsbtn14;
    padding: 9px 20px 10px !important;
    height: 36px;
    &.round {
      border-radius: 18px;
    }
  }
  &.onlyIconInMobile {
    color: $grey500;
    path {
      stroke: $grey500;
    }
    &:hover:not(:disabled) {
      color: $grey900;
      path {
        stroke: $grey900;
      }
    }
    &:disabled {
      color: $grey400;
      path {
        stroke: $grey400;
      }
    }
    @include mobile {
      padding: 9px !important;
    }
  }
  &.round {
    border-radius: 28px;
  }
}
