
@import '@/assets/styles/_common.scss';
.geno-confirm {
  margin: 0 25px;
  min-width: 270px;
  max-width: 510px; // 460+25+25
  min-height: 176px;
  background-color: white;
  > main {
    min-height: 126px;
    padding: 30px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      text-align: center;
    }
  }
  > footer {
    display: flex;
    a {
      width: 50%;
      height: 50px;
      @include font14(normal, #ffffff, 0);
    }
    .no {
      color: $grey8;
      border: none;
      background: $grey1;
    }
  }
}
