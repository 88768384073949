// _common.scss
// : 다른 scss에서 import할 공용 variables와 mixins을 정리\
// class 선언 시, import할 때마다 class가 중복으로 선언되어 비효율적

// https://buefy.github.io/#/documentation/customization
// https://bulma.io/documentation/overview/start/

$tablet708: 708px;
$tablet: 768px;
$desktop: 1024px;
$legacy-side-padding: 30px;
$sidePaddingMobile: 24px;
$gnb-height-mobile: 56px;
$gnb-height-desktop: 84px;
$modal-header-height: 56px;
$remove-side-padding: 2000px;

// Import Bulma's core
@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/functions.sass';
@import '~bulma/sass/utilities/derived-variables.sass';
@import '~bulma/sass/utilities/animations.sass';
@import '~bulma/sass/utilities/mixins.sass';

$grey0: #f8f9fa;
$grey1: #f5f5f5;
$grey2: #e9ecef;
$grey3: #dee2e6;
$grey4: #ced4da;
$grey5: #adb5bd;
$grey6: #868e96;
$grey7: #495057;
$grey8: #343a40;
$red: #e63c78;

$primary: #43b3ae;
$primary-invert: findColorInvert($primary);
$primaryHover: #3b938f;
$primaryWhite1: #6ac0bb;
$primaryWhite2: #8bcdc8;
$primaryWhite3: #a9d9d6;
$primaryWhite4: #c6e6e3;
$primaryWhite5: #e3f2f1;
$primaryWhiteHover: #e3f2f1;
$primaryBlack1: #3b938f;
$primaryBlack2: #327470;
$primaryBlack3: #295654;
$primaryBlack4: #1f3a38;
$primaryBlack5: #14201f;

$graphColor1: #ff7f7e;
$graphColor2: #ffb272;
$graphColor3: #36cba1;
$graphColor4: #3ba2e7;

$blue1: #d1e6f9;
$blue: #3ba2e7;

$grey50: #f8f9fa;
$grey100: #f1f3f5;
$grey200: #e2e5e9;
$grey300: #d6dbe0;
$grey400: #b5bdc5;
$grey500: #89949f;
$grey600: #6e7a87;
$grey700: #57616b;
$grey800: #454d54;
$grey900: #2e3338;
$red50: #fdeae9;
$red100: #fbcfcd;
$red200: #fab4b0;
$red300: #f99892;
$red400: #fa7b73;
$red500: #fb5e54;
$red600: #d7554c;
$red700: #b44b44;
$red800: #923f3a;
$red900: #713430;
$orange50: #fdf0e3;
$orange100: #fbddc1;
$orange200: #fbca9d;
$orange300: #fbb778;
$orange400: #fda452;
$orange500: #ff912b;
$orange600: #da7e2a;
$orange700: #b66c27;
$orange800: #935923;
$orange900: #71461e;
$green50: #e4f5ea;
$green100: #c1e8d1;
$green200: #9eddb7;
$green300: #7bd29e;
$green400: #56c884;
$green500: #31be6a;
$green600: #2da35d;
$green700: #29884f;
$green800: #246e42;
$green900: #1e5534;
$verdigris50: #e6f3f3;
$verdigris100: #c6e5e4;
$verdigris200: #a6d8d6;
$verdigris300: #86cbc8;
$verdigris400: #65bfbb;
$verdigris500: #43b3ae;
$verdigris600: #3d9a95;
$verdigris700: #35817d;
$verdigris800: #2e6966;
$verdigris900: #25514f;
$blue50: #e4f0fb;
$blue100: #c2def6;
$blue200: #a0cbf3;
$blue300: #7cb9f0;
$blue400: #57a6ef;
$blue500: #3294ee;
$blue600: #2f81cb;
$blue700: #2b6daa;
$blue800: #265a89;
$blue900: #20476a;
$indigo50: #e6eaf8;
$indigo100: #c6d0f0;
$indigo200: #a6b5e9;
$indigo300: #859ae2;
$indigo400: #637edc;
$indigo500: #4162d7;
$indigo600: #3c57b8;
$indigo700: #354b9a;
$indigo800: #2e3f7d;
$indigo900: #263361;
$purple50: #f3e9f9;
$purple100: #e5cef3;
$purple200: #d7b2ed;
$purple300: #c995e8;
$purple400: #bb78e4;
$purple500: #ad5ae0;
$purple600: #9651c0;
$purple700: #7f47a1;
$purple800: #683c83;
$purple900: #523166;
$white: #ffffff;
$black: #000000;
//region font mixin
@mixin font72($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 72px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.2;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font60($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 60px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font58($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 58px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font56($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 56px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font50($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 50px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.17;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font48($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 48px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font46($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 46px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font44($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 44px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font40($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 40px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.21;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font36($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 36px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.28;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font30($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 30px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.33;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font32($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 32px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.28;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font28($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 28px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.36;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font26($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 26px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.25;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font24($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 24px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.25;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font22($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 22px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.41;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font20($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 20px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.4;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font18($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 18px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.44;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font16($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 16px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.5;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font15($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 15px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.45;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font14($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 14px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.43;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font13($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 13px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.54;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font12($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 12px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.42;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font11($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 11px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.55;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font10($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 10px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.5;
    font-style: normal;
    font-stretch: normal;
  }
}
@mixin font9($weight, $color, $letter_spacing: normal) {
  & {
    font-size: 9px !important;
    font-weight: $weight !important;
    letter-spacing: $letter_spacing;
    color: $color;
    line-height: 1.56;
    font-style: normal;
    font-stretch: normal;
  }
}
//endregion font mixin

//region 들여쓰기 되는 ul
@mixin custom-ul {
  display: table-cell;
  text-align: left;
}
@mixin custom-li($content: '•') {
  list-style: none;
  display: table-row;
  &:before {
    content: $content;
    display: table-cell;
    text-align: right;
    padding-right: 0.3em;
  }
}
//endregion
