.modal.popup {
  overflow: inherit;
  > .animation-content {
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    position: relative;
  }
  > .modal-close {
    display: none;
  }
  .modal-close {
    position: absolute;
  }
  .modal-close:before,
  .modal-close:after {
    background-color: black;
  }
  .is-large-40.modal-close {
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    width: 40px;
  }
  @include mobile {
    > .modal-background {
      display: none;
    }
  }
  @include tablet {
    margin: 0 auto;
    > .animation-content {
      width: 560px;
      max-height: 643px;
    }
    &.popup920 > .animation-content {
      width: 100%;
      max-height: 100%;
    }
    &.g1-detail-modal > .animation-content {
      width: 708px;
      max-height: 100%;
      background-color: transparent !important;
    }
    &.popup1024 > .animation-content {
      width: 100%;
      max-width: 1023px;
      max-height: 100%;
      margin: 0 -32px;
      background-color: transparent !important;
    }
  }
  @include desktop {
    &.popup920 > .animation-content {
      width: 920px;
      max-height: 90%;
    }
    &.g1-detail-modal > .animation-content {
      width: 960px;
      max-height: 100%;
      background-color: transparent !important;
    }
    &.popup1024 > .animation-content {
      width: 1024px;
      max-height: 100%;
      background-color: transparent !important;
    }
  }
}
.modal.popupJoin {
  @extend .popup;
  @include desktop {
    margin: 0 auto;
    > .animation-content {
      max-height: 690px !important;
    }
  }
  @include tablet {
    margin: 0 auto;
    > .animation-content {
      max-height: 748px;
    }
  }
}

.modal.popup726 {
  @extend .popup;
  @include tablet {
    margin: 0 auto;
    > .animation-content {
      width: 726px;
      max-height: 853px;
    }
  }
}
