
@import '@/assets/styles/_common.scss';

.wrapper {
  margin: 30px;
  max-height: 68.4375vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0; //TODO padding?
  border: none;
}
.modal-contents {
  padding: 24px 24px 0 !important;
  background-color: #ffffff;
  max-width: initial !important;
  max-height: 80vh;
  @include tablet {
    padding: 32px 32px 0 !important;
    width: 500px !important;
    height: 392px !important;
  }
  ::v-deep .link {
    color: $grey8;
    @include tablet {
      &:hover {
        color: $primary !important;
      }
    }
  }
}
footer {
  text-align: center;
  background-color: #ffffff;
}
.btn-not-show:lang(en) {
  @include mobile {
    width: 100px;
    white-space: normal;
  }
}
