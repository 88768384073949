@import '@/assets/styles/_common.scss';
.msa {
  margin-left: auto;
  margin-right: auto;
}
.mt2 {
  margin-top: 2px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt15 {
  margin-top: 15px;
}
.mt16 {
  margin-top: 16px;
}
.mt20 {
  margin-top: 20px;
}
.mt20d {
  @include desktop {
    margin-top: 20px !important;
  }
}
.mt24 {
  margin-top: 24px;
}
.mt25 {
  margin-top: 25px;
}
.mt28 {
  margin-top: 28px;
}
.mt30 {
  margin-top: 30px;
}
.mt30_60_80 {
  margin-top: 30px;
  @include tablet {
    margin-top: 60px;
  }
  @include desktop {
    margin-top: 80px;
  }
}
.mt30 {
  margin-top: 30px;
}
.mt32 {
  margin-top: 32px;
}
.mt35 {
  margin-top: 35px;
}
.mt36 {
  margin-top: 36px;
}
.mt40 {
  margin-top: 40px;
}
.mt40-30 {
  margin-top: 30px;
  @include tablet {
    margin-top: 40px;
  }
}
.mt48 {
  margin-top: 48px;
}
.mt50 {
  margin-top: 50px;
}
.mt60 {
  margin-top: 60px;
}
.mt60_120 {
  margin-top: 60px;
  @include tablet {
    margin-top: 120px;
  }
}
.mt64 {
  margin-top: 64px;
}
.mt65 {
  margin-top: 65px;
}
.mt75 {
  margin-top: 75px;
}
.mt80 {
  margin-top: 80px;
}
.mt90 {
  margin-top: 90px;
}
.mt80_100_150 {
  margin-top: 80px;
  @include tablet {
    margin-top: 100px;
  }
  @include desktop {
    margin-top: 150px;
  }
}
.mt6d {
  @include desktop {
    margin-top: 6px !important;
  }
}
.mt0d {
  @include desktop {
    margin-top: 0px !important;
  }
}
.mt4d {
  @include desktop {
    margin-top: 4px !important;
  }
}
.mt8d {
  @include desktop {
    margin-top: 8px !important;
  }
}
.mt10d {
  @include desktop {
    margin-top: 10px !important;
  }
}
.mt12d {
  @include desktop {
    margin-top: 12px !important;
  }
}
.mt16d {
  @include desktop {
    margin-top: 16px !important;
  }
}
.mt24d {
  @include desktop {
    margin-top: 24px !important;
  }
}
.mt30d {
  @include desktop {
    margin-top: 30px !important;
  }
}
.mt32d {
  @include desktop {
    margin-top: 32px !important;
  }
}
.mt40d {
  @include desktop {
    margin-top: 40px !important;
  }
}
.mt48d {
  @include desktop {
    margin-top: 48px !important;
  }
}
.mt50d {
  @include desktop {
    margin-top: 50px !important;
  }
}
.mt52d {
  @include desktop {
    margin-top: 52px !important;
  }
}
.mt56d {
  @include desktop {
    margin-top: 56px !important;
  }
}
.mt60d {
  @include desktop {
    margin-top: 60px !important;
  }
}
.mt64d {
  @include desktop {
    margin-top: 64px !important;
  }
}
.mt80d {
  @include desktop {
    margin-top: 80px !important;
  }
}
.mt96d {
  @include desktop {
    margin-top: 96px !important;
  }
}
.mt100d {
  @include desktop {
    margin-top: 100px !important;
  }
}
.mt130d {
  @include desktop {
    margin-top: 130px !important;
  }
}
.mt150d {
  @include desktop {
    margin-top: 150px !important;
  }
}
.mt180d {
  @include desktop {
    margin-top: 180px !important;
  }
}

.mb0 {
  margin-bottom: 0px;
}
.mb2 {
  margin-bottom: 2px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb10d {
  @include desktop {
    margin-bottom: 10px !important;
  }
}
.mb16d {
  @include desktop {
    margin-bottom: 16px !important;
  }
}
.mb24d {
  @include desktop {
    margin-bottom: 24px !important;
  }
}
.mb30d {
  @include desktop {
    margin-bottom: 30px !important;
  }
}
.mb32d {
  @include desktop {
    margin-bottom: 32px !important;
  }
}
.mb40d {
  @include desktop {
    margin-bottom: 40px !important;
  }
}
.mb50d {
  @include desktop {
    margin-bottom: 50px !important;
  }
}
.mb80d {
  @include desktop {
    margin-bottom: 80px !important;
  }
}
.mb100d {
  @include desktop {
    margin-bottom: 100px !important;
  }
}
.mb150d {
  @include desktop {
    margin-bottom: 150px !important;
  }
}
.mb180d {
  @include desktop {
    margin-bottom: 180px !important;
  }
}
.ml0 {
  margin-left: 0px;
}
.ml1 {
  margin-left: 1px;
}
.ml4 {
  margin-left: 4px;
}
.ml5 {
  margin-left: 5px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml24 {
  margin-left: 24px;
}
.ml30 {
  margin-left: 30px;
}
.ml48 {
  margin-left: 48px;
}
.ml50 {
  margin-left: 50px;
}
.ml0d {
  @include desktop {
    margin-left: 0px !important;
  }
}
.ml24d {
  @include desktop {
    margin-left: 24px !important;
  }
}
.ml32d {
  @include desktop {
    margin-left: 32px !important;
  }
}
.ml70d {
  @include desktop {
    margin-left: 70px !important;
  }
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
.mr24 {
  margin-right: 24px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 40px;
}
.mr50 {
  margin-right: 50px;
}
.mr4d {
  @include desktop {
    margin-right: 4px !important;
  }
}
.mr20d {
  @include desktop {
    margin-right: 20px !important;
  }
}
