@import '_common.scss';
@import '_restyle.scss';
@import '_components.scss';
@import '_fonts.scss';

[v-cloak] {
  display: none;
}
* {
  scrollbar-width: thin;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
#app-container {
  // from 2021/05
  flex-grow: 1;
  padding-top: $gnb-height-mobile;
  width: 100%;
  width: 100vw;
  @include desktop {
    padding-top: $gnb-height-desktop;
  }
}
.remove-app-container-top-padding {
  margin-top: -$gnb-height-mobile !important;
  @include desktop {
    margin-top: -$gnb-height-desktop !important;
  }
}
#AC {
  // legacy
  // App Container for footer
  width: 100%;
  min-height: 560px;
  padding: 75px $legacy-side-padding 80px;
  flex-grow: 1;
  @include tablet {
    padding-top: 105px;
    padding-bottom: 100px;
    width: $tablet;
    margin: 0 auto;
    min-height: 560px;
  }
  @include desktop {
    padding-top: 180px;
    padding-bottom: 150px;
    width: $desktop;
    margin: 0 auto;
    min-height: 900px;
  }

  &.AC_2020_MAIN {
    padding: 75px 0 80px;
  }
}

.remove-AC-top-padding {
  margin-top: -75px !important;
  @include tablet {
    margin-top: -105px !important;
  }
  @include desktop {
    margin-top: -180px !important;
  }
}
.page-default-top-padding {
  padding-top: calc(#{$gnb-height-mobile} + 24px);
  @include desktop {
    padding-top: calc(#{$gnb-height-desktop} + 48px);
  }
}

.langSelect {
  color: #5b6065;
}

/* The navigation menu links */
.powered-logo-header-except-gnb {
  z-index: 21;
  width: 100%;
  padding: 12px 0 8px 0;
  text-align: center;
  position: fixed;
  top: 0;
  background-color: #f4f4f4;
  .powered-logo-box {
    width: 100%;
    max-width: 1024px;
    display: inline-block;
    margin: auto;
    text-align: right;
    background-color: #f4f4f4;
    @include mobile {
      margin-left: -30px;
    }
  }
}
.background-grey {
  background-color: $grey0;
}
