
.logo-header-except-gnb {
  z-index: 21;
  width: 100%;
  padding: 12px 0 8px 0;
  text-align: center;
  position: fixed;
  top: 0;
  background-color: #ffffff;
}
.genoplan-logo {
  width: 100%;
  max-width: 1024px;
  display: inline-block;
  margin: auto;
  text-align: center;
  background-color: #ffffff;
}
