@import '@/assets/styles/_common.scss';
//all
.pd16 {
  padding: 16px;
}
.pd24 {
  padding: 24px;
}
.pd32 {
  padding: 32px;
}
.pd40 {
  padding: 40px;
}
.pd24d {
  @include desktop {
    padding: 24px;
  }
}
.pd32d {
  @include desktop {
    padding: 32px;
  }
}
.pd40d {
  @include desktop {
    padding: 40px;
  }
}
//top
.pt16 {
  padding-top: 16px;
}
.pt80 {
  padding-top: 80px;
}
.pt150d {
  @include desktop {
    padding-top: 150px !important;
  }
}
.pt160d {
  @include desktop {
    padding-top: 160px !important;
  }
}
//right
.pr4 {
  padding-right: 4px;
}
//bottom
.pb16 {
  padding-bottom: 16px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb24d {
  @include desktop {
    padding-bottom: 24px;
  }
}
//left
