// https://www.notion.so/genoplan/Common-Fonts-a465fe2ff9514189a7f002920e38073f
* {
  &:lang(ko) {
    font-family: 'Pretendard JP', 'Apple SD Gothic Neo' !important;
    word-break: keep-all;
  }
  &:lang(en) {
    font-family: 'Pretendard JP', 'Apple SD Gothic Neo' !important;
    word-break: keep-all;
  }
  &:lang(ja) {
    font-family: 'Pretendard JP', Meiryo, '游ゴシック', YuGothic, 'ヒラギノ角ゴProN W3',
      'Hiragino Kaku Gothic ProN', sans-serif !important;
    word-break: normal;
    word-break: auto-phrase;
  }
  &:lang(zh_hant) {
    font-family: 'Noto Sans TC', sans-serif !important;
    word-break: normal;
  }
}
// text-wrap - https://codersblock.com/blog/nicer-text-wrapping-with-css-text-wrap/#in-closing
h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}
div,
p {
  text-wrap: pretty;
}

$paragraph-color: #5a5a5a;

.Title-1 {
  @include font24(bold, black, -0.8px);
  @include desktop {
    @include font48(bold, black, -1.6px);
  }
}

.Headline-1 {
  @include font36(100, black, -1.4px);
  @include desktop {
    @include font72(100, black, -3px);
  }
}

.Heading-1 {
  @include font18(bold, #343a40, -0.5px);
  @include desktop {
    @include font36(bold, #343a40, -1.4px);
  }
}
.Heading-2 {
  @include font24(300, #343a40, -0.8px);
}
.Heading-3 {
  @include font24(bold, #343a40, -0.8px);
}
.Heading-4 {
  @include font18(bold, #343a40, -0.8px);
  @include desktop {
    @include font24(bold, #343a40, -0.8px);
  }
}
.Heading-5 {
  @include font18(bold, #343a40, -0.5px);
}
.Heading-6 {
  @include font24(bold, #343a40, -0.8px);
  @include desktop {
    @include font36(bold, #343a40, -1.4px);
  }
}

.Heading-7 {
  @include font18(bold, #343a40, -0.8px);
  @include desktop {
    @include font30(bold, #343a40, -1.4px);
  }
}

.Heading-8 {
  @include font20(bold, #343a40, -0.8px);
  @include desktop {
    @include font30(bold, #343a40, -1.4px);
  }
}
.Heading-9 {
  @include font24(bold, #343a40, -0.8px);
  @include desktop {
    @include font36(bold, #343a40, -1.4px);
  }
}
.Subheading-1 {
  @include font18(normal, #343a40, -0.5px);
  @include desktop {
    @include font24(normal, #343a40, -0.8px);
  }
}
.Subheading-2 {
  @include font18(normal, #343a40, -0.5px);
  @include desktop {
    @include font24(normal, #343a40, -0.8px);
  }
}
.Subheading-3 {
  @include font16(bold, #343a40, -0.3px);
  @include desktop {
    @include font18(bold, #343a40, -0.5px);
  }
}
.Subheading-4 {
  @include font14(bold, #343a40, -0.3px);
}
.Subheading-5 {
  @include font14(regular, #343a40, -0.3px);
  @include desktop {
    @include font24(regular, #343a40, -0.8px);
  }
}
.Subheading-6 {
  @include font14(bold, #343a40, -0.3px);
  @include desktop {
    @include font16(bold, #343a40, -0.3px);
  }
}
.Subheading-7 {
  @include font16(bold, #343a40, -0.3px);
  @include desktop {
    @include font24(bold, #343a40, -0.3px);
  }
}
.Subheading-8 {
  @include font16(bold, #343a40, -0.3px);
  @include desktop {
    @include font18(bold, #343a40, -0.3px);
  }
}
.Subheading-9 {
  @include font16(bold, #343a40, -0.3px);
}
.Paragraph-1 {
  @include font14(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font18(normal, $paragraph-color, -0.5px);
  }
}
.Paragraph-2 {
  @include font14(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font18(normal, $paragraph-color, -0.5px);
  }
}
.Paragraph-3 {
  @include font11(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font18(normal, $paragraph-color, -0.5px);
  }
}
.Paragraph-4 {
  @include font14(normal, $paragraph-color, -0.3px);
}
.Paragraph-5 {
  @include font12(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font14(normal, $paragraph-color, -0.3px);
  }
}
.Paragraph-6 {
  @include font12(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font14(normal, $paragraph-color, -0.3px);
  }
}
.Paragraph-7 {
  @include font14(normal, $paragraph-color, -0.3px);
}
.Paragraph-8 {
  @include font12(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font14(normal, $paragraph-color, -0.3px);
  }
}
.Paragraph-9 {
  @include font16(normal, $paragraph-color, -0.3px);
}
.Paragraph-10 {
  @include font14(normal, $paragraph-color, -0.3px);
  @include desktop {
    @include font16(normal, $paragraph-color, -0.3px);
  }
}
.Paragraph-11 {
  @include font18(normal, $paragraph-color, -0.3px);
}
.Paragraph-12 {
  @include font14(normal, #343a40, -0.3px);
  @include desktop {
    @include font16(normal, #343a40, -0.3px);
  }
}
.Paragraph-13 {
  @include font16(normal, #343a40, -0.3px);
  @include desktop {
    @include font18(normal, #343a40, -0.3px);
  }
}
.Paragraph-14 {
  @include font16(normal, #343a40, -0.3px);
}
.Paragraph-15 {
  @include font14(normal, #343a40, -0.3px);
}
.SmallText-1 {
  @include font12(bold, #343a40, -0.3px);
}
.SmallText-2 {
  @include font12(normal, #343a40, -0.3px);
}
.SmallText-3 {
  @include font11(normal, #343a40, -0.5px);
}

// common font
@mixin font($font_size, $line_height) {
  font-size: $font_size;
  line-height: $line_height;
  letter-spacing: -0.01em !important;
}
@mixin font-desktop($font_size, $line_height) {
  @include desktop {
    font-size: $font_size !important;
    line-height: $line_height !important;
    letter-spacing: -0.01em !important;
  }
}
.fs100d {
  @include font-desktop(100px, 120%);
}
.fs80d {
  @include font-desktop(80px, 120%);
}
.fs70d {
  @include font-desktop(70px, 120%);
}
.fs58d {
  @include font-desktop(58px, 120%);
}
.fs56d {
  @include font-desktop(56px, 120%);
}
.fs48d {
  @include font-desktop(48px, 120%);
}
.fs46d {
  @include font-desktop(46px, 120%);
}
.fs36d {
  @include font-desktop(36px, 130%);
}
.fs30d {
  @include font-desktop(30px, 130%);
}
.fs28d {
  @include font-desktop(28px, 130%);
}
.fs24d {
  @include font-desktop(24px, 130%);
}
.fs20d {
  @include font-desktop(20px, 140%);
}
.fs18d {
  @include font-desktop(18px, 145%);
}
.fs16d {
  @include font-desktop(16px, 145%);
}
.fs14d {
  @include font-desktop(14px, 145%);
}
.fs12d {
  @include font-desktop(12px, 145%);
}
.fsbtn16d {
  @include font-desktop(16px, 120%);
}
.fsbtn14d {
  @include font-desktop(14px, 120%);
}
.fs80 {
  @include font(80px, 120%);
}
.fs70 {
  @include font(70px, 120%);
}
.fs58 {
  @include font(58px, 120%);
}
.fs56 {
  @include font(56px, 120%);
}
.fs48 {
  @include font(48px, 120%);
}
.fs46 {
  @include font(46px, 120%);
}
.fs36 {
  @include font(36px, 130%);
}
.fs30 {
  @include font(30px, 130%);
}
.fs28 {
  @include font(28px, 130%);
}
.fs24 {
  @include font(24px, 130%);
}
.fs20 {
  @include font(20px, 140%);
}
.fs18 {
  @include font(18px, 145%);
}
.fs16 {
  @include font(16px, 145%);
}
.fs14 {
  @include font(14px, 145%);
}
.fs12 {
  @include font(12px, 145%);
}
.fs10 {
  @include font(10px, 120%);
}
.fsbtn16 {
  @include font(16px, 120%);
}
.fsbtn14 {
  @include font(14px, 120%);
}
//common font weight
.fw100 {
  font-weight: 100;
}
.fw200 {
  font-weight: 200;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight: 900;
}

.fw100d {
  @include desktop {
    font-weight: 100 !important;
  }
}
.fw200d {
  @include desktop {
    font-weight: 200 !important;
  }
}
.fw300d {
  @include desktop {
    font-weight: 300 !important;
  }
}
.fw400d {
  @include desktop {
    font-weight: 400 !important;
  }
}
.fw500d {
  @include desktop {
    font-weight: 500 !important;
  }
}
.fw600d {
  @include desktop {
    font-weight: 600 !important;
  }
}
.fw700d {
  @include desktop {
    font-weight: 700 !important;
  }
}
.fw800d {
  @include desktop {
    font-weight: 800 !important;
  }
}
.fw900d {
  @include desktop {
    font-weight: 900 !important;
  }
}
