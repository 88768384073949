@import '_colors.scss';
@import '_fonts.scss';
@import '_margins.scss';
@import '_paddings.scss';
@import '_modals.scss';

.width50p {
  width: 50%;
}
.width100p {
  width: 100% !important;
}
.width50 {
  width: 50px !important;
}
.width60 {
  width: 60px !important;
}
.width130 {
  width: 130px !important;
}
.width200 {
  width: 200px !important;
}
.height100p {
  height: 100% !important;
}
.max-width-default {
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
}
.side-container {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  @include desktop {
    max-width: 1024px;
  }
}

@media screen and (max-width: 450px) {
  .m_fixed450Table .table {
    width: 450px;
  }
}
@media screen and (max-width: 600px) {
  .m_fixed600Table .table {
    width: 600px;
  }
}

@media screen and (max-width: 768px) {
  .m_fixed768Table .table {
    width: 768px;
  }
}

.inline_block {
  display: inline-block;
}
.space_between {
  display: flex !important;
  justify-content: space-between;
}
.space_around {
  display: flex;
  justify-content: space-around;
}
.align_center {
  align-items: center;
}
.flex_end {
  display: flex;
  justify-content: flex-end;
}
.invisible0 {
  visibility: hidden;
  max-height: 0;
}
.flex_start {
  display: flex;
  justify-content: flex_start;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.flex__mobile-column__desktop-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
}

//region layout
.pt0 {
  padding-top: 0px !important;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pd0 {
  padding: 0;
}
.pd5 {
  padding: 5px;
}
.pd10 {
  padding: 10px;
}
.pd15 {
  padding: 15px;
}
.pd16 {
  padding: 16px;
}
.pd30 {
  padding: 30px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb30 {
  padding-bottom: 30px;
}
.hr {
  height: 1px;
  background-color: $grey1;
}
.bg-grey1 {
  background-color: $grey1;
}

.NSP {
  //noSidePadding
  margin-left: -$legacy-side-padding !important;
  margin-right: -$legacy-side-padding !important;
}
.remove-side-padding {
  margin-left: -$remove-side-padding !important;
  margin-right: -$remove-side-padding !important;
}

.checkboxMarginLeft {
  margin-left: 1.75em;
}
.center_outter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center {
  position: relative;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
//endregion layout
.thin {
  font-weight: thin !important;
}
.bold {
  font-weight: bold;
}
.tac {
  text-align: center;
}
.tal {
  text-align: left;
}
.tar {
  text-align: right;
}
.tard {
  @include desktop {
    text-align: right !important;
  }
}
.ellipsis-one-line {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #dbdbdb;
}
.grey_box {
  border: 1px solid #e6e6e6;
}
.line_top {
  width: 100%;
  border-top: solid 1px #e1e4e6;
}
.text_link {
  color: $grey7;
  text-decoration: underline;
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
.period {
  &:after {
    content: '.';
    display: inline-block;
    color: $primary;
  }
}

.toTheTop {
  width: 36px;
  height: 36px;
  background: url('./assets/img/group-copy-2.svg') no-repeat;
  background-size: 18px 20px;
  background-position: 8px;
  background-color: #fff;
  border: solid 1px #dbdbdb;
  position: fixed;
  right: 25px;
  // bottom: 25px;
  bottom: 60px;
  z-index: 10;
}

.toTheTopWithBanner {
  bottom: 70px;
  @include tablet {
    bottom: 90px;
  }
}

.radio_w68h45 > .b-radio.radio {
  width: 68px;
  height: 45px;
}

.delete.fr {
  &:before {
    height: 1px;
    background-color: #a9afb3;
  }
  &:after {
    width: 1px;
    background-color: #a9afb3;
  }
}

.txtLink {
  color: #4d5256;
  text-decoration: underline;
}
/**
* @see {@link https://app.asana.com/0/1199549049949741/1201267541368590/f|Common Bullet List}
* https://www.figma.com/file/ADupV6OBkRGkOOwKgl55iR/Components?node-id=3882%3A8709
* @see {@link 약관페이지, 공지사항 게시물은 건드리지 않을 생각입니다.|https://app.asana.com/0/0/1201267541368590/1201308969128061/f}
*/
ul.dash {
  @extend .fs16;
  @extend .grey700;
  margin-left: 10px;
  margin-right: 5px;
  list-style-type: none !important;
  position: relative; // unless this, dash is fixed on scroll
  > li + li {
    margin-top: 4px;
  }
}
ul.dash > li:before {
  font-size: 10px;
  content: '–';
  position: absolute;
  margin-left: -10px;
}
ul.bullet {
  margin-left: 15px;
  list-style-type: none;
}
ul.bullet > li:before {
  content: '•';
  position: absolute;
  font-size: 20px;
  margin-top: -3px;
  margin-left: -15px;
}

.height1 {
  height: 1px;
}
.CP {
  cursor: pointer;
}

.table_notMobileCard {
  &.b-table .table {
    border-collapse: collapse;
  }
  &.b-table .table.has-mobile-cards tr:last-of-type {
    border-bottom: 1px solid #dbdbdb;
  }
  @include mobile {
    &.b-table .table.has-mobile-cards tr {
      border-top: 1px solid #dbdbdb;
      box-shadow: none;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    &.b-table .table.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td {
      min-height: 35px;
      border-bottom: none;
    }
    &.b-table .table.has-mobile-cards tr:not(.detail):not(.is-empty):not(.table-footer) td:before {
      font-weight: normal;
    }
  }
}

.fade0 {
  position: absolute;
  transition: opacity 0.3s;
  opacity: 0;
}
.fadeIn {
  opacity: 1;
}
.liCaution {
  padding-left: 1.3em;
  text-indent: -1.3em;
  &:before {
    content: '※';
    padding-right: 0.3em;
  }
}
.authphone-area {
  display: flex;
  .country-select {
    width: 134px;

    @include mobile {
      select {
        &:lang(ja) {
          font-size: 12px !important;
        }
      }
    }
    @media (max-width: 320px) {
      width: 116px;
      &:lang(en) {
        width: 111px !important;
      }
      select,
      input {
        padding: 0 7px 0px 9px !important;
        font-size: 13px !important;
        &:lang(ja) {
          font-size: 11px !important;
        }
      }
    }
    @include tablet {
      width: 140px;
    }
    > .select {
      width: 100%;
      &:not(.is-multiple):not(.is-loading)::after {
        right: 11px !important;
      }
    }
  }
}

.countryPhoneNum {
  width: calc(100% - 100px);
  margin-left: 10px;
  @include tablet {
    width: calc(100% - 150px);
  }
}
.stickyHeader {
  position: absolute;
  &.active {
    position: fixed;
    z-index: 10;
    width: 100%;
    background-color: white;
    top: 45px;
    @include desktop {
      top: 75px;
    }
  }
  .limitedApp &.active {
    @include desktop {
      top: 45px;
    }
  }
}
.sticky-header {
  z-index: 10;
  position: sticky;
  top: $gnb-height-mobile;
  background-color: white;
  border-bottom: 1px solid #f1f3f5;
  @include desktop {
    top: $gnb-height-desktop;
  }
  .limitedApp & {
    top: 56px;
  }
}

.pd50vh {
  padding-bottom: 50vh;
}

.gp-swiper {
  .swiper-pagination-bullet {
    background-color: $grey4 !important;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: $primary !important;
  }
}

// Major 1.20.2
.common-title {
  @extend .Heading-3;
}
.common-desc {
  @extend .Paragraph-9;
}
.common-section-title {
  @extend .Subheading-9;
  @extend .tac;
}
.common-label {
  @extend .fs14;
  color: $grey7;
}
.required-input.common-label::after {
  content: '*';
}
.required-input .common-label::after {
  content: '*';
}

.anti-app-padding {
  margin-top: -19px;
  @include tablet {
    margin-top: -49px;
  }
  @include desktop {
    margin-top: -96px;
  }
}

.anti-AC {
  // remove padding-top of #AC
  margin-top: -75px;
  @include tablet {
    margin-top: -105px;
  }
  @include desktop {
    margin-top: -180px;
  }
}

.footer-spacer {
  height: 80px;
  @include desktop {
    height: 160px;
  }
}
.br16 {
  border-radius: 16px;
}
.br24 {
  border-radius: 24px;
}
.br16d {
  @include desktop {
    border-radius: 16px !important;
  }
}
