@import '_common.scss';
@import '@/assets/styles/_fonts.scss';

// Import Bulma and Buefy styles
$tablet: 768px;
$desktop: 1024px;
@import '~bulma';
@import '~buefy/src/scss/buefy';

$input-height: 56px;

:focus-visible {
  outline: theme('colors.verdigris.500') auto 1px;
}
button:focus-visible {
  outline-offset: 2px;
}
.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none !important;
}

a,
button {
  @apply text-grey-900;
}
.control-label {
  @extend .fsbtn16;
}
hr {
  height: 1px;
}
// button reset
button {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
button[disabled] {
  @apply cursor-not-allowed;
}
.button {
  border-radius: 0 !important;
  min-width: 45px !important;
  height: $input-height;
  background-color: transparent;
  @extend .fsbtn16;
  color: $grey8;
  justify-content: none !important;
  white-space: normal; // https://app.asana.com/0/1200534127520592/1201181381437030/f

  // is-white
  border-color: #dbdbdb !important;
  &:hover {
    border-color: $grey8 !important;
  }
  &.is-primary {
    background-color: $primary !important;
    border: none;
    &:hover {
      background-color: $primaryHover !important;
      border-color: $primaryHover !important;
    }
    &:active {
      background-color: $primary !important;
      border-color: $primary !important;
      color: white !important;
    }
  }
  &.is-primary1 {
    background-color: $primary !important;
    color: white !important;
    border: 1px solid $primary !important;
    &:hover {
      background-color: $primaryHover !important;
      border-color: $primaryHover !important;
    }
    &:active {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
  &.is-primary-invert {
    color: $primary !important;
    border: 1px solid $primary !important;
    z-index: 3 !important;
    &:hover {
      background-color: $primaryWhiteHover !important;
      border: 1px solid $primary !important;
    }
    &:active {
      background-color: $primaryWhite4 !important;
      border: 1px solid $primary !important;
    }
  }
  &.is-primary2 {
    color: #ff9361 !important;
    border: 1px solid #ff9361 !important;

    &:hover {
      background-color: #ffe8d4 !important;
      border-color: #ff9361 !important;
    }

    &:active {
      background-color: #ffe8d4 !important;
      border-color: #ff9361 !important;
    }
  }

  &.is-primary3 {
    color: #ff9361 !important;
    border: 1px solid #ff9361 !important;

    &:hover {
      background-color: #ff9361 !important;
      color: white !important;
    }

    &:active {
      background-color: #ff9361 !important;
      border-color: #ff9361 !important;
      color: white !important;
    }
  }
  &.is-small {
    height: 36px;
    padding: 10px 20px;
    @extend .fsbtn14;
  }
  &.is-medium {
    @extend .fsbtn16;
  }
}
.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em theme('colors.verdigris.500');
}
.button.is-danger {
  border: 1px solid $red500 !important;
  background-color: $red500;
  color: white;
  &:hover {
    background-color: $red700;
  }
}
.button.is-danger.is-outlined {
  border: 1px solid $red500 !important;
  color: $red500;
  &:hover {
    background-color: $red500 !important;
    color: white !important;
  }
  &:focus {
    background-color: transparent;
    color: $red500;
  }
}
.input {
  height: $input-height !important;
  @extend .fs16;
  box-shadow: initial !important;
  color: $grey900 !important;
  border-color: #dbdbdb !important;
  &:focus {
    border-color: black !important;
  }
  &.is-danger {
    color: #ff8483 !important;
    border-color: #ff8483 !important;
    &:focus {
      box-shadow: initial !important;
    }
  }
}
.input[disabled] {
  border-color: transparent !important;
  color: $grey500 !important;
}
.select select[disabled] {
  color: $grey500 !important;
}
.textarea {
  border-radius: 0px;
  &.is-danger:focus {
    border: 1px solid #ff8483 !important;
    box-shadow: initial !important;
  }
  &:focus {
    border-color: black;
  }
}
.b-tooltip:before,
.b-tooltip:after {
  z-index: 15; // #GNB z-index=20
}

.tooltip:after {
  border-radius: 0 !important;
}
.help {
  @extend .fs12;
  &.is-danger {
    color: #ff8483 !important;
  }
  &.is-primary {
    color: $primary;
  }
}
.has-text-danger {
  color: #ff8483 !important;
}
.b-checkbox.checkbox input[type='checkbox'] + .check {
  border-radius: 0 !important;
}
.b-checkbox.checkbox:hover input[type='checkbox']:not(:disabled):not(:checked) + .check {
  @include mobile {
    border-color: #7a7a7a;
  }
} // https://app.asana.com/0/0/1161772270009720/f
.b-checkbox.checkbox:hover input[type='checkbox']:disabled + .check {
  @include mobile {
    border-color: #7a7a7a;
  }
} // https://app.asana.com/0/0/1175317520540419/f
.b-radio.radio input[type='radio'] + .check {
  width: 19px;
  height: 19px;
  &:before {
    width: 17px;
    height: 17px;
  }
}
.select {
  > select {
    min-width: 80px;
    border-radius: 0 !important;
    width: 100%;
    &:hover {
      border-color: $grey6 !important;
    }
    &:focus {
      border-color: $primary !important;
      box-shadow: initial !important;
    }
  }
}
.select:not(.is-multiple) {
  height: $input-height !important;
  &::after {
    border: 2px solid $verdigris900 !important;
    border-right: 0 !important;
    border-top: 0 !important;
  }
}
.select.is-loading::after {
  top: 20px !important;
  right: 15px !important;
}
.width100p > .select {
  width: 100%;
  & > select {
    width: 100%;
  }
}
.dropdown .button {
  &:hover {
    border: 1px solid $grey6 !important;
  }
  &:active {
    border: 1px solid $primary !important;
    color: $grey7 !important;
  }
}
.dropdown .icon {
  color: $primary !important;
}
.delete {
  background-color: white !important;
  &:before {
    width: 100% !important;
    background-color: black !important;
  }
  &:after {
    height: 100% !important;
    background-color: black !important;
  }
  &:hover {
    background-color: initial !important;
  }
  min-width: 18px !important;
  max-width: 18px !important;
  min-height: 18px !important;
  max-height: 18px !important;
  width: 18px !important;
  height: 18px !important;
}
.is-center {
  margin: 0 auto;
}
.pagination {
  width: 188px !important;
  margin: 0 auto !important;
  > .info {
    width: 100px !important;
    text-align: center !important;
    float: left !important;
    font-size: 14px !important;
  }
}
.pagination-previous,
.pagination-next,
.pagination-link {
  border-radius: 0 !important;
  order: 2 !important;
  &:hover {
    border-color: #7a7a7a !important;
  }
  &:active {
    box-shadow: initial;
    border-color: $primary;
  }
}

//region color
.grey1 {
  color: $grey1 !important;
}
.grey2 {
  color: $grey2 !important;
}
.grey3 {
  color: $grey3 !important;
}
.grey4 {
  color: $grey4 !important;
}
.grey5 {
  color: $grey5 !important;
}
.grey6 {
  color: $grey6 !important;
}
.grey7 {
  color: $grey7 !important;
}
.grey8 {
  color: $grey8 !important;
}
.black {
  color: black !important;
}
.white {
  color: white !important;
}
.red {
  color: $red !important;
}
.paragraph-color {
  color: #5a5a5a !important;
}

.primaryColor {
  color: $primary !important;
}
.primaryWhite1 {
  color: $primaryWhite1 !important;
}
.primaryWhite2 {
  color: $primaryWhite2 !important;
}
.primaryWhite3 {
  color: $primaryWhite3 !important;
}
.primaryWhite4 {
  color: $primaryWhite4 !important;
}
.primaryWhite5 {
  color: $primaryWhite5 !important;
}

.primaryBlack1 {
  color: $primaryBlack1 !important;
}
.primaryBlack2 {
  color: $primaryBlack2 !important;
}
.primaryBlack3 {
  color: $primaryBlack3 !important;
}
.primaryBlack4 {
  color: $primaryBlack4 !important;
}
.primaryBlack5 {
  color: $primaryBlack5 !important;
}

.graphColor1 {
  color: $graphColor1 !important;
}
.graphColor2 {
  color: $graphColor2 !important;
}
.graphColor3 {
  color: $graphColor3 !important;
}
.graphColor4 {
  color: $graphColor4 !important;
}
//endregion color

//region reset
* {
  box-sizing: border-box !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
body {
  color: $grey900;
}

input {
  border-radius: 0 !important;
  padding: 16px 14px !important;
  height: $input-height !important;
}
select {
  padding: 0 14px !important;
  height: $input-height !important;
  @include font14(normal, $grey7);
}
.control.has-icons-right .icon {
  height: $input-height !important;
}
.control.has-icons-right .icon.is-right {
  right: 5px !important;
}
.control.is-loading::after {
  top: 1em !important;
  right: 1em !important;
}
.notices .toast {
  border-radius: 0 !important;
  margin: 20px !important;
}
.toast > div {
  color: white !important;
}
.b-table .table th .th-wrap {
  font-weight: bold !important;
}
.b-table .table tr.detail .detail-container {
  padding: 0 !important;
  & .table {
    background-color: initial !important;
  }
}
.hide_tableDetail > td:first-of-type > a {
  visibility: hidden !important;
}
td:first-of-type > a {
  color: $red !important;
}
.b-table .table th.is-current-sort {
  border-color: #dbdbdb !important;
  & .icon.is-small {
    display: none !important;
  }
}
// center table pagenation
.table-wrapper + .level,
.table-wrapper + .level > .level-right {
  display: initial !important;
}
//endregion reset

//region modal
.modal {
  position: fixed !important;
}
.modal .modal > .animation-content {
  z-index: 40; // mitigate https://app.asana.com/0/1150576649683466/1177588795313735/f
}
.modal-content {
  overscroll-behavior: contain;
}
.modal-content,
.modal-card {
  @include mobile {
    max-height: initial;
  }
}
.modal-card-head,
.modal-card-foot {
  border: none !important;
  border-radius: 0 !important;
  background-color: #f5f5f5 !important;
}
.modal-card-head {
  border-bottom: 1px solid #dbdbdb !important;
  > .delete {
    position: absolute !important;
    top: 17px !important;
    right: 14px !important;
  }
}
.modal-card-title {
  font-size: 18px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.33 !important;
  text-align: left !important;
  color: #000000 !important;
  width: 100%;
}
.modal-card {
  margin: 0 !important;
}
.modal-card-head {
  border-radius: 0 !important;
}
.modal-card-body {
  padding: 25px !important;
}
.dialog .modal-card .modal-card-body.is-titleless {
  border-radius: 0 !important;
}
.dialog-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: center;
  color: $grey8;
}
.dialog-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.34px;
  color: $grey8;
}

.dialog-desc {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.47px;
  color: $grey6;
}
.dialog-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  letter-spacing: -0.7px;
  color: $grey8;
}
.dialog-body {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: $grey8;
}
.modal-card .modal-card-foot {
  border-radius: 0 !important;
  border-top: 1px solid #dbdbdb !important;
}
.modal-card-foot > .button {
  min-width: 80px !important;
  font-weight: normal !important;
  height: 36px;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
//endregion modal

//region join
.notification {
  border-radius: 0 !important;
}
.delete {
  background: transparent !important;
}
//endregion join

.dropdown-content {
  border-radius: 0 !important;
}

.min-width80 > .dropdown-menu {
  margin-left: -15px !important;
  & > .dropdown-content {
    min-width: 80px !important;
    width: 80px !important;
  }
}

.nav-label {
  @include desktop {
    padding: 0.5rem 1rem !important;
  }
}

//region tab
.tabs {
  font-size: inherit !important;
}
.tabs a {
  font-weight: bold;
  color: $grey6 !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #f1f3f5 !important;
  @include desktop {
    border-bottom-width: 4px !important;
  }
  &:hover {
    color: #000000 !important;
    border-bottom-color: #f1f3f5 !important;
  }
}
.tabs li {
  margin-bottom: -1px !important;
  @include desktop {
    margin-bottom: -3px !important;
  }
}
.tabs li.is-active a {
  border-bottom-color: $primary !important;
  color: $primary !important;
}
.tabs ul {
  border-bottom-width: 4px !important;
  border-bottom-color: #f1f3f5;
  @include desktop {
    border-bottom-width: 8px !important;
  }
}
//endregion tab

.is-block-mobile,
.is-block-tablet,
.is-block-desktop {
  display: none;
}
.is-block-mobile-only,
.is-block-tablet-only {
  display: none;
  @include desktop {
    display: none !important;
  }
}
.is-noscroll {
  overflow-x: hidden; // 상품 팝업 768px에 x 스크롤 지우기 위해
}

.b-checkbox.checkbox {
  &:focus {
    input[type='checkbox'] + .check {
      box-shadow: none !important;
    }
  }
}
.intact.b-checkbox {
  .control-label {
    padding: 0;
  }
}

* {
  //Remove focus overlay-color of webview
  -webkit-tap-highlight-color: transparent;
}
*:not(input):not(textarea) {
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

// IE 에서 발생하는 버그픽스 modified : dennis
.modal-card {
  overflow: auto !important;
  height: auto !important;
}

.help {
  color: #868e96;
}
.columns {
  margin: 0;
}

.iOSWebView {
  // iphone notch
  padding-top: calc(env(safe-area-inset-top) * 0.9) !important;
  .ios-reportG1 {
    padding-top: 0;
    & #close {
      top: calc(env(safe-area-inset-top) * 1.8) !important;
    }
    & .g1-report-all-category-wrapper {
      & #close {
        top: 0 !important;
      }
    }
  }
  .stickyHeader.active {
    padding-top: calc(10px + env(safe-area-inset-top) * 0.9) !important;
  }

  .modal {
    // for iOS popup
    -webkit-overflow-scrolling: touch;

    &.is-active {
      margin-top: calc(env(safe-area-inset-top) * 0.9) !important;
      @include mobile {
        background-color: white !important;
      }
    }
    .modal-close {
      top: calc(env(safe-area-inset-top) * 0.2) !important;
    }
  }
}
#webWidget {
  // zendesk widget #IT-1988
  margin-top: env(safe-area-inset-top) !important;
}

.progress.is-primary {
  &::-webkit-progress-value {
    background-color: $primary;
  }
  &::-moz-progress-bar {
    background-color: $primary;
  }
  &::-ms-fill {
    background-color: $primary;
  }
  &:indeterminate {
    background-image: linear-gradient(to right, $primary 30%, #dbdbdb 30%);
  }
}
.common-input-field > .help {
  @extend .fs12;
  color: $grey7;
  margin-top: 8px;
}
.field:not(:last-child) {
  margin-bottom: 8px;
}

.tag:not(body).is-primary {
  background-color: $primary;
}

.v-popper__popper {
  z-index: 15; // #GNB z-index=20
}
.v-popper__wrapper {
  border-radius: 16px; // 팝업 배경 잔상 제거 https://app.asana.com/0/0/1202104618157468/1202288835280721/f
}
.v-popper__inner {
  max-width: 272px;
  padding: 16px;
  border-radius: 16px;
  @extend .fs12; // https://www.figma.com/file/Ju2ro7SYKW2DSH0VGziPmV?node-id=0:1#164817017
}
.v-popper--theme-dropdown .v-popper__inner {
  color: $grey700;
}
