
@import '@/assets/styles/_common.scss';

article {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 0;
  background: white;
  box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.08);
  z-index: 30;
  @include desktop {
    padding: 32px 0;
  }
}
::v-deep {
  > a {
    color: $grey900;
    text-decoration: underline;
  }
}
