@import '_common.scss';
.grey50 {
  color: $grey50;
}
.grey100 {
  color: $grey100;
}
.grey200 {
  color: $grey200;
}
.grey300 {
  color: $grey300;
}
.grey400 {
  color: $grey400;
}
.grey500 {
  color: $grey500;
}
.grey600 {
  color: $grey600;
}
.grey700 {
  color: $grey700;
}
.grey800 {
  color: $grey800;
}
.grey900 {
  color: $grey900;
}
.red50 {
  color: $red50;
}
.red100 {
  color: $red100;
}
.red200 {
  color: $red200;
}
.red300 {
  color: $red300;
}
.red400 {
  color: $red400;
}
.red500 {
  color: $red500;
}
.red600 {
  color: $red600;
}
.red700 {
  color: $red700;
}
.red800 {
  color: $red800;
}
.red900 {
  color: $red900;
}
.orange50 {
  color: $orange50;
}
.orange100 {
  color: $orange100;
}
.orange200 {
  color: $orange200;
}
.orange300 {
  color: $orange300;
}
.orange400 {
  color: $orange400;
}
.orange500 {
  color: $orange500;
}
.orange600 {
  color: $orange600;
}
.orange700 {
  color: $orange700;
}
.orange800 {
  color: $orange800;
}
.orange900 {
  color: $orange900;
}
.green50 {
  color: $green50;
}
.green100 {
  color: $green100;
}
.green200 {
  color: $green200;
}
.green300 {
  color: $green300;
}
.green400 {
  color: $green400;
}
.green500 {
  color: $green500;
}
.green600 {
  color: $green600;
}
.green700 {
  color: $green700;
}
.green800 {
  color: $green800;
}
.green900 {
  color: $green900;
}
.verdigris50 {
  color: $verdigris50;
}
.verdigris100 {
  color: $verdigris100;
}
.verdigris200 {
  color: $verdigris200;
}
.verdigris300 {
  color: $verdigris300;
}
.verdigris400 {
  color: $verdigris400;
}
.verdigris500 {
  color: $verdigris500;
}
.verdigris600 {
  color: $verdigris600;
}
.verdigris700 {
  color: $verdigris700;
}
.verdigris800 {
  color: $verdigris800;
}
.verdigris900 {
  color: $verdigris900;
}
.blue50 {
  color: $blue50;
}
.blue100 {
  color: $blue100;
}
.blue200 {
  color: $blue200;
}
.blue300 {
  color: $blue300;
}
.blue400 {
  color: $blue400;
}
.blue500 {
  color: $blue500;
}
.blue600 {
  color: $blue600;
}
.blue700 {
  color: $blue700;
}
.blue800 {
  color: $blue800;
}
.blue900 {
  color: $blue900;
}
.indigo50 {
  color: $indigo50;
}
.indigo100 {
  color: $indigo100;
}
.indigo200 {
  color: $indigo200;
}
.indigo300 {
  color: $indigo300;
}
.indigo400 {
  color: $indigo400;
}
.indigo500 {
  color: $indigo500;
}
.indigo600 {
  color: $indigo600;
}
.indigo700 {
  color: $indigo700;
}
.indigo800 {
  color: $indigo800;
}
.indigo900 {
  color: $indigo900;
}
.purple50 {
  color: $purple50;
}
.purple100 {
  color: $purple100;
}
.purple200 {
  color: $purple200;
}
.purple300 {
  color: $purple300;
}
.purple400 {
  color: $purple400;
}
.purple500 {
  color: $purple500;
}
.purple600 {
  color: $purple600;
}
.purple700 {
  color: $purple700;
}
.purple800 {
  color: $purple800;
}
.purple900 {
  color: $purple900;
}
.white {
  color: $white;
}
.black {
  color: $black;
}
